// ------------------------------------------------------  웹 잘작동하는 코드드
/* import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

const Success = () => {
  const { currentUser, isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigated, setIsNavigated] = useState(false);
  const [fallbackVisible, setFallbackVisible] = useState(false);

  useEffect(() => {
    // 네비게이션 중복 호출 방지
    if (isAuthLoading || isNavigated) return;

    const confirmPayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      let itemName;
      const encodedItemName = searchParams.get('itemName');
      if (encodedItemName) {
        try {
          itemName = decodeURIComponent(atob(encodedItemName));
        } catch (e) {
          console.error('itemName 디코딩 실패:', e);
        }
      }
      // URL에 itemName이 없으면 로컬스토리지에서 가져오기
      if (!itemName) {
        itemName = localStorage.getItem('pendingItemName');
      }
      
      const pg_token = searchParams.get('pg_token');
      const tid = localStorage.getItem('kakaoPay_tid');

      if (!pg_token || !tid) {
        console.error('결제 정보가 충분하지 않습니다.');
        alert('올바르지 않은 결제 접근입니다.');
        setIsNavigated(true);
        navigate('/');
        return;
      }

      try {
        // Firebase의 "products" 컬렉션에서 상품 정보를 조회 (상품명이 "text" 필드에 저장되어 있다고 가정)
        const productsRef = collection(db, 'products');
        const q = query(productsRef, where('name', '==', itemName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          throw new Error('상품 정보를 찾을 수 없습니다.');
        }
        // 첫 번째 일치하는 문서를 사용 (상품명이 고유하다고 가정)
        const productDoc = querySnapshot.docs[0];
        const productData = productDoc.data();

        // 구매 내역을 Firestore에 기록
        await addDoc(collection(db, 'users', currentUser.uid, 'downloads'), {
          itemName,
          link: productData.gdlink || '',
          createdAt: new Date(),
          paymentStatus: 'completed',
          tid
        });

        // 결제 완료 후 로컬스토리지 정리
        localStorage.removeItem('kakaoPay_tid');
        localStorage.removeItem('pendingItemName');

        alert('결제가 완료되었습니다. 마이페이지에서 다운로드할 수 있습니다.');
        setIsNavigated(true);

        // 모바일/PC 환경에 따라 리다이렉트 처리
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (isMobile) {
          // 모바일에서는 앱으로 전환(딥링크) 시도 – 만약 실패하면 fallback UI 표시
          window.location.href = `myapp://success?itemName=${encodedItemName}`;
          setTimeout(() => {
            setFallbackVisible(true);
          }, 3000);
        } else {
          setTimeout(() => {
            navigate('/mypage');
          }, 1500);
        }
      } catch (error) {
        console.error('결제 확인 중 오류:', error);
        alert('결제 확인 중 오류가 발생했습니다.');
        setIsNavigated(true);
        navigate('/mypage');
      }
    };

    if (currentUser) {
      confirmPayment();
    } else {
      alert('로그인 후 이용 가능합니다.');
      setIsNavigated(true);
      navigate('/login');
    }
  }, [isAuthLoading, currentUser, location.search, navigate, isNavigated]);

  return (
    <div style={{
      maxWidth: '1200px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#fff'
    }}>
      {fallbackVisible ? (
        <div style={{ textAlign: 'center' }}>
          <p>
            앱으로 자동 전환되지 않습니다. 앱을 설치하지 않으시면, 웹에서 계속 진행할 수 있습니다.
          </p>
          <button onClick={() => navigate('/mypage')}>
            웹에서 계속 진행
          </button>
          <button onClick={() => window.location.href = "https://your-app-download-page.com"}>
            앱 다운로드
          </button>
        </div>
      ) : (
        <div style={{ textAlign: "center", lineHeight: '30px', fontSize: '20px' }}>
          결제를 확인 중입니다...
        </div>
      )}
    </div>
  );
};

export default Success; */


// -------------------------------------------------- 자동로그인 로직 추가가
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const Success = () => {
  const { currentUser, isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigated, setIsNavigated] = useState(false);
  const [fallbackVisible, setFallbackVisible] = useState(false);

  useEffect(() => {
    // 네비게이션 중복 호출 방지
    if (isAuthLoading || isNavigated) return;

    const confirmPayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      
      // 자동 로그인 로직: URL 쿼리로 전달된 idToken이 있으면 사용하여 로그인 시도
      const idToken = searchParams.get('idToken');
      if (idToken && !currentUser) {
        try {
          const auth = getAuth();
          await signInWithCustomToken(auth, idToken);
          console.log("자동 로그인 성공");
        } catch (error) {
          console.error("자동 로그인 실패:", error);
        }
      }
      
      // 로그인 상태 확인 (업데이트된 사용자 정보 사용)
      const updatedUser = getAuth().currentUser;
      if (!updatedUser) {
        alert('로그인 후 이용 가능합니다.');
        setIsNavigated(true);
        navigate('/login');
        return;
      }

      // 상품명 복원: URL에 인코딩된 itemName이 있으면 디코딩, 없으면 localStorage에서 가져옴
      let itemName;
      const encodedItemName = searchParams.get('itemName');
      if (encodedItemName) {
        try {
          itemName = decodeURIComponent(atob(encodedItemName));
        } catch (e) {
          console.error('itemName 디코딩 실패:', e);
        }
      }
      if (!itemName) {
        itemName = localStorage.getItem('pendingItemName');
      }
      
      // 결제 관련 필수 파라미터 확인
      const pg_token = searchParams.get('pg_token');
      const tid = localStorage.getItem('kakaoPay_tid');

      if (!pg_token || !tid) {
        console.error('결제 정보가 충분하지 않습니다.');
        alert('올바르지 않은 결제 접근입니다.');
        setIsNavigated(true);
        navigate('/');
        return;
      }

      try {
        // Firestore에서 상품 정보를 조회 (상품명이 고유하다고 가정)
        const productsRef = collection(db, 'products');
        const q = query(productsRef, where('name', '==', itemName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          throw new Error('상품 정보를 찾을 수 없습니다.');
        }
        const productDoc = querySnapshot.docs[0];
        const productData = productDoc.data();

        // 구매 내역을 Firestore에 기록
        await addDoc(collection(db, 'users', updatedUser.uid, 'downloads'), {
          itemName,
          link: productData.gdlink || '',
          createdAt: new Date(),
          paymentStatus: 'completed',
          tid
        });

        // 결제 완료 후 로컬스토리지 정리
        localStorage.removeItem('kakaoPay_tid');
        localStorage.removeItem('pendingItemName');

        alert('결제가 완료되었습니다. 마이페이지에서 다운로드할 수 있습니다.');
        setIsNavigated(true);

        // 모바일/PC 환경에 따라 리다이렉트 처리
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (isMobile) {
          // 모바일인 경우 앱 딥링크로 전환 시도 – 실패하면 fallback UI 표시
          window.location.href = `myapp://success?itemName=${encodedItemName}`;
          setTimeout(() => {
            setFallbackVisible(true);
          }, 3000);
        } else {
          setTimeout(() => {
            navigate('/mypage');
          }, 1500);
        }
      } catch (error) {
        console.error('결제 확인 중 오류:', error);
        alert('결제 확인 중 오류가 발생했습니다.');
        setIsNavigated(true);
        navigate('/mypage');
      }
    };

    confirmPayment();
  }, [isAuthLoading, currentUser, location.search, navigate, isNavigated]);

  return (
    <div style={{
      maxWidth: '1200px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#fff'
    }}>
      {fallbackVisible ? (
        <div style={{ textAlign: 'center' }}>
          <p>
            앱으로 자동 전환되지 않습니다. 앱을 설치하지 않으시면, 웹에서 계속 진행할 수 있습니다.
          </p>
          <button onClick={() => navigate('/mypage')}>
            웹에서 계속 진행
          </button>
          <button onClick={() => window.location.href = "https://your-app-download-page.com"}>
            앱 다운로드
          </button>
        </div>
      ) : (
        <div style={{ textAlign: "center", lineHeight: '30px', fontSize: '20px' }}>
          결제를 확인 중입니다...
        </div>
      )}
    </div>
  );
};

export default Success;
