import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Paperbg2 from '../img/paperbg2-1.webp';
import axios from 'axios';

// ----------------------------- 스타일드 컴포넌트 -----------------------------
const BasicAllWrap = styled.div`
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 5px;
  margin-bottom: 50px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 4px 4px 15px rgba(0,0,0,0.4);
  background-image: url(${Paperbg2});
  text-align: center;
  padding-bottom: 200px;
`;

const TopWrap = styled.div`
  display: flex; 
  margin: 0 auto; 
  max-width: 1200px; 
  margin-bottom: 40px; 
  overflow: hidden; 
  padding: 20px; 
  box-sizing: border-box; 
  justify-content: center; 

  .topwrapimg {
    width: 500px; 
    height: 500px; 
    box-shadow: 3px 3px 15px rgba(0,0,0,0.4); 
    @media screen and (max-width:830px) {
      width: 400px; 
      height: 400px; 
    }
    @media screen and (max-width:730px) {
      width: 300px; 
      height: 300px; 
    }
    @media screen and (max-width:600px) {
      width: 210px; 
      height: 210px; 
    }
  }
  .topwraptext {
    line-height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    padding: 30px;
    @media screen and (max-width:730px) {
      line-height: 30px;
      padding: 30px;
    }
    @media screen and (max-width:600px) {
      line-height: 20px;
      padding: 15px;
      span {
        font-size: 16px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`;

const Backbutton = styled.button`
  width: 100px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
  transition: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const Detailfont = styled.div`
  span {
    font-size: 30px; 
    line-height: 60px;
    font-weight: 700; 
  }
  p {
    margin-bottom: 50px;
  }
  @media screen and (max-width:550px) {
    span {
      font-size: 30px; 
      line-height: 60px;
      font-weight: 700; 
    }
    p {
      margin-bottom: 50px;
      font-size: 14px;
      letter-spacing: -0.5px;
    }
  }
`;

const Twoimgwrap = styled.div`
  max-width: 1200px; 
  height: auto;
  display: flex; 
  justify-content: space-around; 
  margin-bottom: 100px; 
  img {
    width: 450px; 
    height: 450px; 
    outline: 1px solid rgba(0,0,0,0.3); 
    @media screen and (max-width:950px) {
      width: 400px; 
      height: 400px; 
    }
    @media screen and (max-width:850px) {
      width: 300px; 
      height: 300px; 
    }
    @media screen and (max-width:650px) {
      width: 240px; 
      height: 240px; 
    }
    @media screen and (max-width:530px) {
      width: 190px; 
      height: 190px; 
    }
  }
`;

const Lasttextwrap = styled.div`
  max-width: 1200px; 
  height: auto; 
  margin-bottom: 50px; 
  padding: 30px; 
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  h2 {
    font-size: 30px; 
    font-weight: 700; 
    margin-bottom: 30px;
  }
  p {
    line-height: 25px;
    span {
      font-size: 18px; 
      font-weight: 700; 
      text-decoration: underline; 
      text-underline-offset: 5px;
    }
  }
  @media screen and (max-width:800px) {
    margin-bottom: 50px; 
    padding: 30px; 
    h2 {
      font-size: 30px; 
      font-weight: 700; 
      margin-bottom: 30px;
    }
    p {
      line-height: 23px;
      font-size: 14px;
      span {
        letter-spacing: -1px;
        line-height: 30px;
        font-size: 18px; 
        font-weight: 700; 
        text-decoration: underline; 
        text-underline-offset: 5px;
      }
    }
  }
  @media screen and (max-width:640px) {
    margin-bottom: 50px; 
    padding: 30px; 
    box-sizing: border-box;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    h2 {
      font-size: 24px; 
      font-weight: 700; 
      margin-bottom: 30px;
    }
    p {
      line-height: 23px;
      font-size: 14px;
      span {
        letter-spacing: -1px;
        line-height: 30px;
        font-size: 18px; 
        font-weight: 700; 
        text-decoration: underline; 
        text-underline-offset: 5px;
      }
    }
  }
  @media screen and (max-width:540px) {
    margin-bottom: 50px; 
    padding: 30px; 
    box-sizing: border-box;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    h2 {
      letter-spacing: -0.5px;
      font-size: 18px; 
      font-weight: 700; 
      margin-bottom: 30px;
    }
    p {
      letter-spacing: -0.5px;
      line-height: 22px;
      font-size: 12px;
      span {
        letter-spacing: -1px;
        line-height: 30px;
        font-size: 16px; 
        font-weight: 700; 
        text-decoration: underline; 
        text-underline-offset: 5px;
      }
    }
  }
`;

const Kakaobutton = styled.button`
  width: 200px;
  height: 60px;
  background-color: ${({ isAgreed, currentUser, isPurchased }) =>
    isAgreed && currentUser && !isPurchased ? 'rgba(242,218,0,1)' : 'rgba(0,0,0,0.4)'};
  border: 1px solid rgba(0,0,0,0);
  border-radius: 15px;
  font-weight: 700;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.4);
  cursor: ${({ isAgreed, currentUser, isPurchased }) =>
    isAgreed && currentUser && !isPurchased ? 'pointer' : 'not-allowed'};
  margin-top: 20px;
  @media screen and (max-width:600px) {
    width: 140px;
    height: 60px;
    background-color: ${({ isAgreed, currentUser, isPurchased }) =>
      isAgreed && currentUser && !isPurchased ? 'rgba(242,218,0,1)' : 'rgba(0,0,0,0.4)'};
    border: 1px solid rgba(0,0,0,0);
    border-radius: 15px;
    font-weight: 700;
    font-size: 12px;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.4);
    cursor: ${({ isAgreed, currentUser, isPurchased }) =>
      isAgreed && currentUser && !isPurchased ? 'pointer' : 'not-allowed'};
    margin-top: 20px;
  }
`;

// ----------------------------- 컴포넌트 시작 -----------------------------
const Detailpages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);

  // 상품 정보를 Firestore에서 가져오기
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchProduct() {
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setItem(docSnap.data());
        } else {
          setItem(null);
        }
      } catch (error) {
        console.error("상품 상세 불러오기 실패:", error);
        setItem(null);
      } finally {
        setLoading(false);
      }
    }
    fetchProduct();
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };

  const goTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  // 결제 버튼 클릭 시 처리 (웹용 결제 로직은 그대로 사용)
  const handlePayment = async () => {
    if (!item) {
      alert("상품 정보를 불러올 수 없습니다.");
      return;
    }
    if (isPurchased) {
      alert("이미 구매한 상품입니다.");
      return;
    }
    if (!isAgreed) {
      alert("약관에 동의해주세요.");
      return;
    }
    setLoading(true);
    try {
      const orderId = `ORDER_${Date.now()}`;
      // 현재 로그인된 사용자 정보는 AuthContext 등에서 관리
      // 웹에서는 현재 로그인 상태를 이미 확인한다고 가정
      const userId = "currentUserId"; // 실제 로그인된 사용자 ID로 교체 필요
      const itemName = item.name;
      const encodedItemName = btoa(encodeURIComponent(itemName));
      const quantity = 1;
      const price = parseInt(item.price.replace(/[^0-9]/g, "")) || 10000;
      const totalAmount = price * quantity;

      const approvalUrl = `${window.location.origin}/success?itemName=${encodedItemName}`;
      const cancelUrl = `${window.location.origin}/cancel`;
      const failUrl = `${window.location.origin}/fail`;

      console.log("Encoded item name:", encodedItemName);
      
      const data = new URLSearchParams({
        cid: "TC0ONETIME",
        partner_order_id: orderId,
        partner_user_id: userId,
        item_name: itemName,
        quantity: String(quantity),
        total_amount: String(totalAmount),
        vat_amount: String(Math.floor(totalAmount / 11)),
        tax_free_amount: "0",
        approval_url: approvalUrl,
        cancel_url: cancelUrl,
        fail_url: failUrl,
      });

      const response = await axios.post(
        "https://kapi.kakao.com/v1/payment/ready",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_ADMIN_KEY}`,
          },
        }
      );

      if (!response.data.next_redirect_pc_url) {
        throw new Error("결제 요청 실패");
      }

      localStorage.setItem("kakaoPay_tid", response.data.tid);
      window.location.href = response.data.next_redirect_pc_url;
    } catch (error) {
      console.error("결제 요청 중 오류 발생:", error);
      alert("결제 요청 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!item) {
    return <div>상품 정보를 불러올 수 없습니다.</div>;
  }

  

  return (
    <>
      <BasicAllWrap>
        <Backbutton onClick={goBack}>뒤로가기</Backbutton>
        <TopWrap>
          <img className="topwrapimg" src={item.src} alt={item.alt} />
          <div className="topwraptext">
            <p><span style={{ fontWeight: '700' }}>제품명 : {item.name}</span></p>
            <p><span style={{ fontWeight: '700' }}>가격 : {item.price}원</span></p>
            <Kakaobutton
              onClick={handlePayment}
              disabled={loading || !isAgreed || isPurchased}
              style={{
                width: '150px',
                height: '60px',
                backgroundColor: isAgreed && !isPurchased ? 'rgba(242,218,0,1)' : 'rgba(0,0,0,0.4)',
                border: '1px solid rgba(0,0,0,0)',
                borderRadius: '15px',
                fontWeight: "700",
                fontSize: '12px',
                color: '#000',
                boxShadow: '4px 4px 10px rgba(0,0,0,0.4)',
                cursor: isAgreed && !isPurchased ? 'pointer' : 'not-allowed',
                marginTop: '20px',
              }}
            >
              {loading ? '처리 중...' : isPurchased ? '이미 구매함' : '카카오페이로 결제하기'}
            </Kakaobutton>
            <div style={{ fontSize: '12px', marginTop: '10px', color: '#666' }}>
              <p style={{ color: 'green' }}>로그인 상태: 로그인됨</p>
              <p style={{ color: isAgreed ? 'green' : 'rgba(0,0,0,0.6)' }}>
                약관 동의: {isAgreed ? '동의됨' : '동의 필요'}
              </p>
              <p style={{ color: isAgreed ? 'green' : 'rgba(0,0,0,0.6)' }}>
                버튼 상태: {loading || !isAgreed || isPurchased ? '비활성화' : '활성화'}
              </p>
            </div>
          </div>
        </TopWrap>
        <button onClick={goBottom} style={{ margin: '0 auto 40px', width: '300px', height: '40px', borderRadius: '10px', border: 'rgba(0,0,0,0.4)', boxShadow: '3px 3px 10px rgba(0,0,0,0.4)', fontWeight: '700', cursor: 'pointer' }}>
          약관으로 바로가기
        </button>
        <Detailfont>
          <span>{item.name}</span>
          <p>
            본 상품은 독점이미지가 아닌 여러 회원에게 판매하는 일반상품입니다.<br /><br />
            결제가 완료되면 마이페이지에서 다운로드 할 수 있습니다.
          </p>
        </Detailfont>
        <Twoimgwrap>
          <img src={item.src} alt={item.alt} />
          {/* <img src={item.src2} alt={item.alt} /> */}
        </Twoimgwrap>
        <Lasttextwrap>

       
          <h2>디자인의 사용가능 범위와 저작권에 대한 설명</h2>
          <p>
            <span>제공하는 파일형식 : png 또는 ai -상업적 사용 가능</span><br /><br />
            <span>== 상업적 사용 불가 항목 ==</span> <br />
            - 사업체 고유의 성질을 나타내는 로고, 고유캐릭터 등의 용도로 사용 불가 메신저, SNS 이모티콘 제안(카카오톡 등), 출판 등 저작권에 혼동을 주는 용도로 활용 불가<br />
            - 플랫폼 내 사용자에게 디자인 템플릿으로 제공 불가 (미리캔버스 등 디자인 플랫폼) 그림책, 동화책, 컬러링북 등 출판물 및 그림엽서 사용불가<br />
            위의 항목 외에 키링, 스마트톡, 쿠션, 스티커, 풍선, 토퍼, 굿즈, 의류, 자수, 현수막 상품에 상업적 사용 가능합니다.<br /><br />
            <span >== 2차가공 불가 ==</span><br />
            구매한 파일은 '실물'상품에 적용해서만 상업적 사용이 가능합니다.<br />
            -디지털파일 형식으로 배포 및 판매, 게시, 복제, 공유, 양도, 제 3자에게 사용권 부여 등 불법적 행위는 절대 금하며, 이를 위반 시 법적 책임을 질 수 있습니다.<br />
            -제공되는 파일 형식 외 다른 형식의 파일로 변형하여 사용할 수 있으나, 본 사용규제는 변하지 않습니다.<br />
            -캘리그라피의 경우 글자 조합, 크기 조절, 재배치, 일부 제거하여 사용하는 것을 허용합니다.<br />
            -그림이미지의 경우 크기 조절, 재배치, 그림의 일부 제거하여 따로 사용이 가능하며, 이미지 합성이 필요한 경우는 구미고미일러스트 파일 내에서만의 합성을 허용합니다.<br />
            (타사 이미지와 합성 불가)<br />
            -폰트파일의 경우, 인쇄 및 출판, 영상물, 웹사이트, 옥외광고, 로고 전반에 걸쳐 개인적, 상업적 사용 가능합니다. 단, 폰트파일을 수정 및 복제하여 재배포할 수 없습니다.<br />
            -이외의 수정이나 변형이 필요한 경우엔 문의를 남겨주시면 답변드리겠습니다.<br /><br />
            <span >-본 이미지의 저작권은 구미고미일러스트에 있으며, 구매자는 비독점 사용권을 갖습니다.</span><br /><br />
            <span >
              구매자는 해당 파일의 저작권 등록 및 저작권을 행사하는 행위를 할 수 없습니다.<br />
              이미지를 사용한 상품의 판매페이지 내 디자인 출처 [구미고미일러스트]를 기재해주시기 바랍니다. (폰트 제외)<br /><br />
              결제 완료후 마이페이지에 추가되는 해당파일의 다운로드 링크를 눌러 즉시 다운로드할 수 있으며, <br/>

              <span>&#8251;&#8251; 디지털 파일 특성상 결제 후 교환 및 환불이 불가합니다.&#8251;&#8251;</span>
            </span><br /><br />
            </p>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px',marginBottom:'100px' }}>
              <input
                type="checkbox"
                id="agree"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
              />
              <label htmlFor="agree" style={{ marginLeft: '10px', fontSize: '16px' }}>
                위의 내용에 동의하고 결제 진행
              </label>
              <button onClick={goTop} style={{ marginLeft: '20px', padding: '3px', border: '1px solid rgba(0,0,0,0.2)', borderRadius: '5px', boxShadow: '2px 2px 5px rgba(0,0,0,0.2)', cursor: 'pointer'}}>상단으로 이동</button>
            </div>
      
          </Lasttextwrap>
      </BasicAllWrap>
    </>
  );
};

export default Detailpages;
