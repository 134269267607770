import React from 'react'

const Dummy = () => {
  return (
    <>
      <div>
        <p>dummy page.</p>
      </div>
    </>
  )
}

export default Dummy
