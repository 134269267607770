import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import Paperbg2 from '../img/paperbg2-1.webp';

// ----------------------------- 스타일드 컴포넌트 -----------------------------
const MonoWrap = styled.div`
  max-width: 1200px;
  margin: 0 auto 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ImageWrapper = styled.div`
  outline: 1px solid rgba(0,0,0,0.2);
  overflow: hidden;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  margin: 10px auto;
  width: 250px; /* 한 줄에 4개씩 배치되도록 설정 */
  box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
  transition: 0.2s;

  &:hover {
    scale: 1.05;
  }

  @media screen and (max-width:550px) {
    width: 160px;
    height: 230px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
    transition: 0.2s;

    &:hover {
      scale: 1.05;
    }
  }
`;

const Img = styled.img`
  width: 250px;
  height: 250px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);

  @media screen and (max-width:550px) {
    width: 160px;
    height: 160px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  }
`;

const Text = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
`;

const PaginationButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  background-color: #ddd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;

  &:disabled {
    background-color: #f2da00;
    cursor: not-allowed;
  }
`;

const BasicAllWrap = styled.div`
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 5px;
  padding-top: 50px;
  padding-bottom: 5px;
  margin-bottom: 50px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 4px 4px 15px rgba(0,0,0,0.4);
  background-image: url(${Paperbg2});
`;

const Textwrap = styled.div`
  max-width: 1200px;
  height: auto; 
  margin: 0 auto; 
  text-align: center;

  h1 {
    font-size: 30px; 
  }
  p {
    font-size: 16px; 
    margin-top: 20px; 
    line-height: 25px; 
    margin-bottom: 30px;
  }

  @media screen and (max-width:550px) {
    h1 {
      font-size: 30px; 
    }
    p {
      letter-spacing: -1px;
      font-size: 14px; 
      margin-top: 20px; 
      line-height: 25px; 
      margin-bottom: 30px;
    }
  }
`;

// ----------------------------- 컴포넌트 시작 -----------------------------
const Mono = () => {
  const navigate = useNavigate();
  const [monoImages, setMonoImages] = useState([]);
  const [currentPageMono, setCurrentPageMono] = useState(1); // 현재 페이지
  const itemsPerPage = 16; // 페이지당 항목 수

  // Firestore에서 'products' 컬렉션에서 category가 "basic"인 상품만 가져오기
  useEffect(() => {
    async function fetchProducts() {
      try {
        const q = query(collection(db, 'products'), where('category', '==', 'basic'));
        const snapshot = await getDocs(q);
        const items = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMonoImages(items);
      } catch (error) {
        console.error('상품 불러오기 실패:', error);
      }
    }
    fetchProducts();
  }, []);

  // 페이지네이션을 위한 현재 페이지 이미지 슬라이스
  const indexOfLastItemMono = currentPageMono * itemsPerPage;
  const indexOfFirstItemMono = indexOfLastItemMono - itemsPerPage;
  const currentMonoImages = monoImages.slice(indexOfFirstItemMono, indexOfLastItemMono);
  const totalMonoPages = Math.ceil(monoImages.length / itemsPerPage);

  const monohandleClick = (id) => {
    navigate(`/detail/${id}`);
  };

  const handlePageChangeMono = (pageNumber) => {
    setCurrentPageMono(pageNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // 컴포넌트가 처음 렌더링될 때 상단 스크롤
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BasicAllWrap>
        <Textwrap>
          <h1>일반구매 일러스트</h1>
          <p>
            일반구매 일러스트 카테고리입니다!<br />
            여러 회원에게 중복으로 판매하며, 여러 업장에서 사용될 수 있습니다.<br />
            1:1판매 일러스트보다 저렴한 가격으로 귀여운 일러스트를 사용해보세요!<br />
          </p>
        </Textwrap>

        <MonoWrap>
          {currentMonoImages.map(mono => (
            <ImageWrapper key={mono.id} onClick={() => monohandleClick(mono.id)}>
              <Img src={mono.src} alt={mono.alt} />
              <Text>{mono.text}</Text>
              <Text style={{ fontWeight: '700' }}>{mono.price}원</Text>
            </ImageWrapper>
          ))}
        </MonoWrap>

        {/* 페이지네이션 버튼 */}
        <div style={{ textAlign: 'center', marginTop: '-50px', marginBottom: '100px' }}>
          {[...Array(totalMonoPages)].map((_, i) => (
            <PaginationButton
              key={i}
              onClick={() => handlePageChangeMono(i + 1)}
              disabled={currentPageMono === i + 1}
            >
              {i + 1}
            </PaginationButton>
          ))}
        </div>
      </BasicAllWrap>
    </>
  );
};

export default Mono;
